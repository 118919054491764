////
/// @group helpers/typography
////

@import "../tools/px-to-rem";

/// 'Common typography' helper
///
/// Sets the font family and associated properties, such as font smoothing. Also
/// overrides the font for print.
///
/// @param {List} $font-family [$govuk-font-family] Font family to use
/// @access public

@mixin govuk-typography-common($font-family: $govuk-font-family) {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // If the user is using the default GDS Transport font we need to include
  // the font-face declarations.
  @if $govuk-include-default-font-face {
    @include _govuk-font-face-gds-transport;
  }

  @include govuk-media-query($media-type: print) {
    font-family: $govuk-font-family-print;
  }
}

/// Text colour helper
///
/// Sets the text colour, including a suitable override for print.
///
/// @access public

@mixin govuk-text-colour {
  color: $govuk-text-colour;

  @include govuk-media-query($media-type: print) {
    color: $govuk-print-text-colour;
  }
}

/// Regular font weight helper
///
/// @param {Boolean} $important [false] - Whether to mark declarations as
///   `!important`. Generally Used to create override classes.
/// @access public

@mixin govuk-typography-weight-regular($important: false) {
  font-weight: $govuk-font-weight-regular if($important, !important, null);
}

/// Bold font weight helper
///
/// @param {Boolean} $important [false] - Whether to mark declarations as
///   `!important`. Generally Used to create override classes.
/// @access public

@mixin govuk-typography-weight-bold($important: false) {
  font-weight: $govuk-font-weight-bold if($important, !important, null);
}

/// Convert line-heights specified in pixels into a relative value, unless
/// they are already unit-less (and thus already treated as relative values)
/// or the units do not match the units used for the font size.
///
/// @param {Number} $line-height Line height
/// @param {Number} $font-size Font size
/// @return {Number} The line height as either a relative value or unmodified
///
/// @access private

@function _govuk-line-height($line-height, $font-size) {
  @if not unitless($line-height) and unit($line-height) == unit($font-size) {
    $line-height: $line-height / $font-size;
  }

  @return $line-height;
}

/// Responsive typography helper
///
/// Takes a point from the responsive 'font map' as an argument (the size as it
/// would appear on tablet and above), and uses it to create font-size and
/// line-height declarations for different breakpoints, and print.
///
/// Example font map:
///
/// 19: (
///   null: (
///     font-size: 16px,
///     line-height: 20px
///   ),
///   tablet: (
///     font-size: 19px,
///     line-height: 25px
///   ),
///   print: (
///     font-size: 14pt,
///     line-height: 1.15
///   )
/// );
///
/// @param {Number} $size - Point from the spacing scale (the size as it would
///   appear on tablet and above)
/// @param {Number} $override-line-height [false] - Non responsive custom line
///   height. Omit to use the line height from the font map.
/// @param {Boolean} $important [false] - Whether to mark declarations as
///   `!important`.
///
/// @throw if `$size` is not a valid point from the spacing scale
///
/// @access public

@mixin govuk-typography-responsive($size, $override-line-height: false, $important: false) {
  @if not map-has-key($govuk-typography-scale, $size) {
    @error "Unknown font size `#{$size}` - expected a point from the typography scale.";
  }

  $font-map: map-get($govuk-typography-scale, $size);

  @each $breakpoint, $breakpoint-map in $font-map {
    $font-size: map-get($breakpoint-map, "font-size");
    $font-size-rem: govuk-px-to-rem($font-size);

    $line-height: _govuk-line-height(
      $line-height: if($override-line-height, $override-line-height, map-get($breakpoint-map, "line-height")),
      $font-size: $font-size
    );

    // Mark rules as !important if $important is true - this will result in
    // these variables becoming strings, so this needs to happen *after* they
    // are used in calculations
    $font-size: $font-size if($important, !important, null);
    $font-size-rem: $font-size-rem if($important, !important, null);
    $line-height: $line-height if($important, !important, null);

    @if not $breakpoint {
      font-size: $font-size-rem;
      line-height: $line-height;
    } @else if $breakpoint == "print" {
      @include govuk-media-query($media-type: print) {
        font-size: $font-size;
        line-height: $line-height;
      }
    } @else {
      @include govuk-media-query($from: $breakpoint) {
        font-size: $font-size-rem;
        line-height: $line-height;
      }
    }
  }
}

/// Font helper
///
/// @param {Number | Boolean} $size Point from the spacing scale (the size as it
///   would appear on tablet and above). Use `false` to avoid setting a size.
/// @param {String} $weight [regular] - Weight: `bold` or `regular`
/// @param {Boolean} $tabular [false] - Whether to use tabular numbers or not
/// @param {Number} $line-height [false] - Line-height, if overriding the
///   default
///
/// @throw if `$size` is not a valid point from the spacing scale (or false)
///
/// @access public

@mixin govuk-font($size, $weight: regular, $tabular: false, $line-height: false) {
  @include govuk-typography-common;

  @if $tabular {
    font-feature-settings: "tnum" 1;

    @supports (font-variant-numeric: tabular-nums) {
      font-feature-settings: normal;
      font-variant-numeric: tabular-nums;
    }
  }

  @if $weight == regular {
    @include govuk-typography-weight-regular;
  } @else if $weight == bold {
    @include govuk-typography-weight-bold;
  }

  @if $size {
    @include govuk-typography-responsive($size, $override-line-height: $line-height);
  }
}
