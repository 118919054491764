@import "../tag/index";

@include govuk-exports("govuk/component/phase-banner") {
  .govuk-phase-banner {
    padding-top: govuk-spacing(2);
    padding-bottom: govuk-spacing(2);

    border-bottom: 1px solid $govuk-border-colour;
  }

  .govuk-phase-banner__content {
    @include govuk-font($size: 16);
    @include govuk-text-colour;

    display: table;
    margin: 0;
  }

  .govuk-phase-banner__content__tag {
    @include govuk-typography-responsive($size: 16);
    margin-right: govuk-spacing(2);

    // When forced colour mode is active, for example to provide high contrast,
    // the background colour of the tag is the same as the rest of the page. To ensure
    // that the tag is perceived as separate to the rest of the text in the phase banner,
    // it is made bold.
    @media screen and (forced-colors: active) {
      font-weight: bold;
    }
  }

  .govuk-phase-banner__text {
    display: table-cell;
    vertical-align: middle;
  }
}
